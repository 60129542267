import React, { useEffect, useRef } from 'react';

const NetworkBackground = () => {
  const canvasRef = useRef(null);
  const mousePositionRef = useRef({ x: 0, y: 0 });
  const lastProcessedPositionRef = useRef({ x: 0, y: 0 });
  const moveCounterRef = useRef(0);
  const lastUpdateTimeRef = useRef(0);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    class SpatialGrid {
      constructor(width, height, cellSize) {
        this.cellSize = cellSize;
        this.cols = Math.ceil(width / cellSize);
        this.rows = Math.ceil(height / cellSize);
        this.grid = new Array(this.cols * this.rows).fill().map(() => []);
      }

      getIndex(x, y) {
        const col = Math.floor(x / this.cellSize);
        const row = Math.floor(y / this.cellSize);
        return row * this.cols + col;
      }

      insert(node) {
        const index = this.getIndex(node.x, node.y);
        if (index >= 0 && index < this.grid.length) {
          this.grid[index].push(node);
        }
      }

      getNodesInRadius(x, y, radius) {
        const startCol = Math.max(0, Math.floor((x - radius) / this.cellSize));
        const endCol = Math.min(this.cols - 1, Math.floor((x + radius) / this.cellSize));
        const startRow = Math.max(0, Math.floor((y - radius) / this.cellSize));
        const endRow = Math.min(this.rows - 1, Math.floor((y + radius) / this.cellSize));

        const nodes = [];
        for (let row = startRow; row <= endRow; row++) {
          for (let col = startCol; col <= endCol; col++) {
            const index = row * this.cols + col;
            nodes.push(...this.grid[index]);
          }
        }
        return nodes;
      }

      clear() {
        this.grid.forEach(cell => cell.length = 0);
      }
    }

    class Node {
      constructor(x, y, z) {
        this.x = x;
        this.y = y;
        this.z = z;
        this.baseX = x;
        this.baseY = y;
        this.targetX = x;
        this.targetY = y;
        this.connections = [];
        this.size = Math.random() * 1.5 + 0.5;
      }

      project(width, height, fov, distance) {
        const factor = fov / (distance + this.z);
        const x = this.x * factor + width / 2;
        const y = this.y * factor + height / 2;
        return { x, y, factor };
      }

      addConnection(node) {
        if (!this.connections.includes(node)) {
          this.connections.push(node);
        }
      }

      setTarget(x, y) {
        this.targetX = x;
        this.targetY = y;
      }

      update(deltaTime) {
        const ease = 1 - Math.pow(0.001, deltaTime);
        this.x += (this.targetX - this.x) * ease;
        this.y += (this.targetY - this.y) * ease;

        // Spring force to return to original position
        const springFactor = 0.01;
        this.targetX += (this.baseX - this.targetX) * springFactor;
        this.targetY += (this.baseY - this.targetY) * springFactor;
      }
    }

    const createNodes = (count, width, height, depth) => {
      const nodes = [];
      for (let i = 0; i < count; i++) {
        const x = (Math.random() - 0.5) * width * 0.8;
        const y = (Math.random() - 0.5) * height * 0.8;
        const z = Math.random() * depth - depth / 2;
        nodes.push(new Node(x, y, z));
      }
      return nodes;
    };

    const connectNodes = (nodes, maxDistance) => {
      for (let i = 0; i < nodes.length; i++) {
        for (let j = i + 1; j < nodes.length; j++) {
          const dx = nodes[i].x - nodes[j].x;
          const dy = nodes[i].y - nodes[j].y;
          const dz = nodes[i].z - nodes[j].z;
          const distance = Math.sqrt(dx * dx + dy * dy + dz * dz);
          if (distance < maxDistance) {
            nodes[i].addConnection(nodes[j]);
            nodes[j].addConnection(nodes[i]);
          }
        }
      }
    };

    const drawNetwork = (ctx, nodes, width, height, mouseX, mouseY) => {
      ctx.clearRect(0, 0, width, height);
      const fov = 250;
      const distance = 100;

      nodes.sort((a, b) => b.z - a.z);

      ctx.lineWidth = 1.0;
      nodes.forEach(node => {
        const { x, y, } = node.project(width, height, fov, distance);
        node.connections.forEach(connectedNode => {
          const { x: cx, y: cy } = connectedNode.project(width, height, fov, distance);
          const distanceToMouse = Math.sqrt((x - mouseX) ** 2 + (y - mouseY) ** 2);
          const alpha = Math.min(0.2, 1 - (node.z + 400) / 800) * (1 - distanceToMouse / width);
          ctx.strokeStyle = `rgba(100, 180, 255, ${alpha})`;
          ctx.beginPath();
          ctx.moveTo(x, y);
          ctx.lineTo(cx, cy);
          ctx.stroke();
        });
      });

      nodes.forEach(node => {
        const { x, y, factor } = node.project(width, height, fov, distance);
        const distanceToMouse = Math.sqrt((x - mouseX) ** 2 + (y - mouseY) ** 2);
        const size = Math.max(0.1, node.size * factor);
        const alpha = Math.min(1, 1 - (node.z + 400) / 800) * (1 - distanceToMouse / width);
        const gradient = ctx.createRadialGradient(x, y, 0, x, y, size * 2);
        gradient.addColorStop(0, `rgba(180, 220, 255, ${alpha})`);
        gradient.addColorStop(1, 'rgba(100, 180, 255, 0)');
        ctx.fillStyle = gradient;
        ctx.beginPath();
        ctx.arc(x, y, size * 2, 0, Math.PI * 2);
        ctx.fill();
      });
    };

    const nodes = createNodes(200, canvas.width, canvas.height, 600);
    connectNodes(nodes, 150);

    const spatialGrid = new SpatialGrid(canvas.width, canvas.height, 50);

    const updateNodes = (currentTime) => {
      const { x: mouseX, y: mouseY } = mousePositionRef.current;
      const { x: lastX, y: lastY } = lastProcessedPositionRef.current;
      const dx = mouseX - lastX;
      const dy = mouseY - lastY;
      const distanceMoved = Math.sqrt(dx * dx + dy * dy);
      const timeSinceLastUpdate = currentTime - lastUpdateTimeRef.current;

      if (distanceMoved > 5 && timeSinceLastUpdate > 50) {
        const affectedNodes = spatialGrid.getNodesInRadius(mouseX, mouseY, 100);
        const forceX = dx * 0.1;
        const forceY = dy * 0.1;

        affectedNodes.forEach(node => {
          node.setTarget(node.x + forceX, node.y + forceY);
        });

        lastProcessedPositionRef.current = { x: mouseX, y: mouseY };
        lastUpdateTimeRef.current = currentTime;
      }

      const deltaTime = currentTime - lastUpdateTimeRef.current;
      nodes.forEach(node => node.update(deltaTime));
    };

    const animate = (currentTime) => {
      spatialGrid.clear();
      nodes.forEach(node => spatialGrid.insert(node));

      updateNodes(currentTime);
      drawNetwork(ctx, nodes, canvas.width, canvas.height, mousePositionRef.current.x, mousePositionRef.current.y);
      animationFrameId = requestAnimationFrame(animate);
    };

    // Initialize lastUpdateTimeRef to current time
    lastUpdateTimeRef.current = performance.now();
    // Initialize lastProcessedPositionRef to center of screen
    lastProcessedPositionRef.current = { x: canvas.width / 2, y: canvas.height / 2 };
    // Initialize mousePositionRef to center of screen
    mousePositionRef.current = { x: canvas.width / 2, y: canvas.height / 2 };

    animate(performance.now());

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  useEffect(() => {
    const handleMouseMove = (event) => {
      moveCounterRef.current++;
      if (moveCounterRef.current % 4 === 0) {
        mousePositionRef.current = { x: event.clientX, y: event.clientY };
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return <canvas ref={canvasRef} className="fixed inset-0 bg-black" />;
};

const App = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-white p-4 relative overflow-hidden">
      <NetworkBackground />
      
      <div className="relative z-10">
        <h1 className="text-5xl sm:text-7xl md:text-8xl lg:text-9xl font-bold mb-8" style={{ fontFamily: 'Avenir Next LT Pro, sans-serif', color: '#1aabd7' }}>STRALA</h1>
      </div>
    </div>
  );
};

export default App;